export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInteger: any;
  Date: any;
  DateTime: any;
  Void: any;
};

export type AbTest = {
  enabled?: Maybe<Scalars['Boolean']>;
  groupConnections?: Maybe<Array<Maybe<AbTestGroupConnection>>>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type AbTestGroupConnection = {
  group?: Maybe<Group>;
  groupId?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type AbTestGroupConnectionInput = {
  groupId?: InputMaybe<Scalars['String']>;
  percentage?: InputMaybe<Scalars['Float']>;
};

export type AbTestInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  groupConnections?: InputMaybe<Array<InputMaybe<AbTestGroupConnectionInput>>>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type AcceptedTermsAndConditions = {
  /** ISO-8601 */
  acceptedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
};

export enum Action {
  SAVE = 'SAVE',
  SAVE_DRAFT = 'SAVE_DRAFT'
}

export type Address = {
  postalCode?: Maybe<Scalars['String']>;
  postalPlace?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
};

export type Broadband = {
  alias?: Maybe<Scalars['String']>;
  broadbandId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export enum ClassificationType {
  AGRICULTURE = 'AGRICULTURE',
  BUSINESS = 'BUSINESS',
  HOUSEHOLD = 'HOUSEHOLD',
  RECREATION = 'RECREATION',
  UNKNOWN = 'UNKNOWN'
}

export type CompanyAccountCount = {
  oneMonthAgo?: Maybe<CountCompanyReciept>;
  oneWeekAgo?: Maybe<CountCompanyReciept>;
  today?: Maybe<CountCompanyReciept>;
};

export type Content = {
  content?: Maybe<Scalars['String']>;
  feedItemLink?: Maybe<FeedItemLink>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<ContentImage>;
  imageUrl?: Maybe<Scalars['String']>;
  link?: Maybe<ContentLink>;
  pageContent?: Maybe<ContentEditorData>;
  pageImage?: Maybe<ContentImage>;
  pageTitle?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  publishDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  segmentIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  segmentNotIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  uniqueImpressions: Scalars['BigInteger'];
};

export type ContentEditorData = {
  html?: Maybe<Scalars['String']>;
  json?: Maybe<Scalars['String']>;
};

export type ContentEditorDataInput = {
  html?: InputMaybe<Scalars['String']>;
  json?: InputMaybe<Scalars['String']>;
};

export type ContentImage = {
  provider?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ContentImageInput = {
  provider?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ContentInput = {
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<ContentImageInput>;
  link?: InputMaybe<ContentLinkInput>;
  pageContent?: InputMaybe<ContentEditorDataInput>;
  pageImage?: InputMaybe<ContentImageInput>;
  pageTitle?: InputMaybe<Scalars['String']>;
  /** ISO-8601 */
  publishDate?: InputMaybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  segmentIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  segmentNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
};

export type ContentLink = {
  type?: Maybe<ContentLinkType>;
  url?: Maybe<Scalars['String']>;
  view?: Maybe<Scalars['String']>;
  viewType?: Maybe<Scalars['String']>;
};

export type ContentLinkInput = {
  type?: InputMaybe<ContentLinkType>;
  url?: InputMaybe<Scalars['String']>;
  view?: InputMaybe<Scalars['String']>;
  viewType?: InputMaybe<Scalars['String']>;
};

export enum ContentLinkType {
  APP = 'APP',
  APP_CONTENT_PAGE = 'APP_CONTENT_PAGE',
  NO_LINK = 'NO_LINK',
  WEB = 'WEB'
}

export type ContentText = {
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  lastUpdated?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ContentTextInput = {
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  /** ISO-8601 */
  lastUpdated?: InputMaybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type Contract = {
  _id?: Maybe<Scalars['String']>;
  accountGuid?: Maybe<Scalars['String']>;
  accountingPointGuid?: Maybe<Scalars['String']>;
  customerGuid?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  lastUpdated?: Maybe<Scalars['DateTime']>;
  meterNumber?: Maybe<Scalars['String']>;
  meterPointId?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export enum ContractStatus {
  ACTIVE = 'ACTIVE',
  CEASED = 'CEASED',
  IN_TAKEOVER = 'IN_TAKEOVER',
  PROCESSING = 'PROCESSING',
  TO_BE_CEASED = 'TO_BE_CEASED',
  UNKNOWN = 'UNKNOWN',
  WAITING_TRANSFER = 'WAITING_TRANSFER'
}

export type CountCompanyReciept = {
  id?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  timestamp?: Maybe<Scalars['DateTime']>;
  userCount?: Maybe<Scalars['Float']>;
};

export type CountUsersWithSegmentsResponse = {
  inSegments: Scalars['BigInteger'];
  total: Scalars['BigInteger'];
};

export type CursorPaginationResponse_Content = {
  items?: Maybe<Array<Maybe<Content>>>;
  total?: Maybe<Scalars['BigInteger']>;
};

export type CursorPaginationResponse_HelpPage = {
  items?: Maybe<Array<Maybe<HelpPage>>>;
  total?: Maybe<Scalars['BigInteger']>;
};

export type CursorPaginationResponse_Notification = {
  items?: Maybe<Array<Maybe<Notification>>>;
  total?: Maybe<Scalars['BigInteger']>;
};

export type CursorPaginationResponse_ServiceMessage = {
  items?: Maybe<Array<Maybe<ServiceMessage>>>;
  total?: Maybe<Scalars['BigInteger']>;
};

export type CursorPaginationResponse_User = {
  items?: Maybe<Array<Maybe<User>>>;
  total?: Maybe<Scalars['BigInteger']>;
};

export type Dashboard = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  widgets?: Maybe<Array<Maybe<DashboardWidget>>>;
};

export type DashboardWidget = {
  id: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  settingsJson?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  widgets?: Maybe<Array<Maybe<DummyWidget>>>;
};

export type DataPoint = {
  key?: Maybe<Scalars['String']>;
  value: Scalars['Int'];
};

export type DefaultAddress = {
  buildingNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postalName?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
};

export type DummyWidget = {
  id: Scalars['String'];
  settingsJson?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ElectricityContract = {
  consumptionClassification?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  dateFrom?: Maybe<Scalars['Date']>;
  /** ISO-8601 */
  dateTo?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  productCode?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Product>>>;
  status?: Maybe<ContractStatus>;
};

export enum ElectricityPriceZone {
  NO1 = 'NO1',
  NO2 = 'NO2',
  NO3 = 'NO3',
  NO4 = 'NO4',
  NO5 = 'NO5'
}

export type Entry_String_DateTime = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['DateTime']>;
};

export type Entry_String_Float = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type Entry_String_String = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type FeatureFlag = {
  disabledMessage?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  /** ISO-8601 */
  from?: Maybe<Scalars['Date']>;
  global?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  propertiesJson?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  to?: Maybe<Scalars['Date']>;
  usersCount?: Maybe<Scalars['BigInteger']>;
  version?: Maybe<Scalars['Int']>;
};

export type FeatureFlagInput = {
  disabledMessage?: InputMaybe<Scalars['String']>;
  displayName: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** ISO-8601 */
  from?: InputMaybe<Scalars['Date']>;
  global?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  propertiesJson?: InputMaybe<Scalars['String']>;
  /** ISO-8601 */
  to?: InputMaybe<Scalars['Date']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type FeedItemLink = {
  type?: Maybe<FeedItemLinkType>;
  url?: Maybe<Scalars['String']>;
  view?: Maybe<Scalars['String']>;
  viewDataJSON?: Maybe<Scalars['String']>;
  viewType?: Maybe<Scalars['String']>;
};

export enum FeedItemLinkType {
  APP = 'APP',
  APP_CONTENT_PAGE = 'APP_CONTENT_PAGE',
  NO_LINK = 'NO_LINK',
  WEB = 'WEB'
}

export type FileRecieptStatus = {
  cashFlowFiles?: Maybe<Scalars['Float']>;
  cashFlowWithinExpectedRange: Scalars['Boolean'];
  decimalPercentageCashFlow?: Maybe<Scalars['Float']>;
  decimalPercentageMeteringValue?: Maybe<Scalars['Float']>;
  failedCashFlowFiles?: Maybe<Scalars['Float']>;
  failedMeteringValues?: Maybe<Scalars['Float']>;
  meteringValues?: Maybe<Scalars['Float']>;
  meteringValuesWithinExpectedRange: Scalars['Boolean'];
  /** ISO-8601 */
  time?: Maybe<Scalars['DateTime']>;
};

export type Fjutt = {
  anleggsId?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
};

export type GridOwnerMapping = {
  ecomonId: Scalars['String'];
  id: Scalars['String'];
  mergedCompanies?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  numberOfUsers?: Maybe<Scalars['BigInteger']>;
  websiteURL: Scalars['String'];
};

export type GridOwnerMappingInput = {
  ecomonId: Scalars['String'];
  id: Scalars['String'];
  mergedCompanies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  numberOfUsers?: InputMaybe<Scalars['BigInteger']>;
  websiteURL: Scalars['String'];
};

export type GridOwnerSummary = {
  capacityModelType: Scalars['String'];
  energyModelType: Scalars['String'];
  gln: Scalars['String'];
  id: Scalars['String'];
  supported: Scalars['Boolean'];
  timezone: Scalars['String'];
  validFrom: Scalars['String'];
};

export type GridOwnerUsersCount = {
  count: Scalars['BigInteger'];
  ecomonId?: Maybe<Scalars['String']>;
  fraktalName?: Maybe<Scalars['String']>;
};

export type Group = {
  displayName: Scalars['String'];
  featureFlagKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  featureFlags?: Maybe<Array<Maybe<FeatureFlag>>>;
  id?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  users?: Maybe<CursorPaginationResponse_User>;
  usersCanOptIn?: Maybe<Scalars['Boolean']>;
  usersCount?: Maybe<Scalars['BigInteger']>;
};


export type GroupUsersArgs = {
  count?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  startIndex?: InputMaybe<Scalars['Int']>;
};

export type GroupInput = {
  displayName: Scalars['String'];
  featureFlagKeys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  usersCanOptIn?: InputMaybe<Scalars['Boolean']>;
};

export type GroupedMeteringValue = {
  cashFlowFiles?: Maybe<Scalars['Float']>;
  meteringValues?: Maybe<Scalars['Float']>;
  /** ISO-8601 */
  time?: Maybe<Scalars['DateTime']>;
};

export type HarkToken = {
  accessToken?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  created?: Maybe<Scalars['DateTime']>;
  devices?: Maybe<Array<Maybe<Fjutt>>>;
  harkUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type HelpPage = {
  content?: Maybe<ContentEditorData>;
  /** ISO-8601 */
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<ContentImage>;
  imageUrl?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Maybe<HelpPageLocation>>>;
  order: Scalars['Int'];
  published: Scalars['Boolean'];
  segmentIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  segmentNotIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
};

export type HelpPageInput = {
  content?: InputMaybe<ContentEditorDataInput>;
  /** ISO-8601 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<ContentImageInput>;
  locations?: InputMaybe<Array<InputMaybe<HelpPageLocation>>>;
  order: Scalars['Int'];
  published: Scalars['Boolean'];
  segmentIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  segmentNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
};

export enum HelpPageLocation {
  FORBRUK = 'FORBRUK',
  HJEM = 'HJEM',
  KOSTNAD = 'KOSTNAD',
  MEG = 'MEG',
  MEG_BOLIGER_OG_AVTALER = 'MEG_BOLIGER_OG_AVTALER',
  MEG_FAKTURA = 'MEG_FAKTURA',
  MEG_FAKTURAOVERSIKT = 'MEG_FAKTURAOVERSIKT',
  MEG_TILGANGER = 'MEG_TILGANGER',
  MEG_VERVING = 'MEG_VERVING',
  SPOTPRIS = 'SPOTPRIS',
  TJENESTER = 'TJENESTER'
}

export type InfoScreenData = {
  activeUsersLast1Day: Scalars['BigInteger'];
  activeUsersLast7Days: Scalars['BigInteger'];
  activeUsersLast30Days: Scalars['BigInteger'];
  activeUsersLast30DaysNTENo: Scalars['BigInteger'];
  contentCards?: Maybe<Array<Maybe<Content>>>;
  conversionsLast30DaysNTENo: Scalars['BigInteger'];
  conversionsNTENo?: Maybe<Array<Maybe<DataPoint>>>;
  currentActiveUsers: Scalars['BigInteger'];
  popularPages?: Maybe<Array<Maybe<DataPoint>>>;
  popularPagesNTENo?: Maybe<Array<Maybe<DataPoint>>>;
  totalSales?: Maybe<Array<Maybe<DataPoint>>>;
  usersTodayNTENo: Scalars['BigInteger'];
};

export type Intercom = {
  androidHash?: Maybe<Scalars['String']>;
  iosHash?: Maybe<Scalars['String']>;
};

export type LabelSegment = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  numberOfSegments: Scalars['Int'];
  segmentIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  segmentNotIn?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LabelSegmentInput = {
  id?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  segmentIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  segmentNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LatestProcessingEvents = {
  cashFlowEvent?: Maybe<ProcessingEvent>;
  meteringValuesEvent?: Maybe<ProcessingEvent>;
};

export type Message = {
  /** ISO-8601 */
  created?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  lastUpdated?: Maybe<Scalars['DateTime']>;
  messageButton?: Maybe<MessageButton>;
  pushNotificationText?: Maybe<Scalars['String']>;
  pushNotificationTitle?: Maybe<Scalars['String']>;
  recipients?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ISO-8601 */
  scheduledDelivery?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  scheduledExpiration?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MessageStatus>;
  statusRecipients?: Maybe<Array<Maybe<StatusRecipient>>>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<MessageType>;
};

export type MessageButton = {
  text?: Maybe<Scalars['String']>;
  view?: Maybe<Scalars['String']>;
  viewType?: Maybe<ViewType>;
};

export type MessageButtonInput = {
  text?: InputMaybe<Scalars['String']>;
  view?: InputMaybe<Scalars['String']>;
  viewType?: InputMaybe<ViewType>;
};

export type MessageInput = {
  messageButton?: InputMaybe<MessageButtonInput>;
  pushNotificationText?: InputMaybe<Scalars['String']>;
  pushNotificationTitle?: InputMaybe<Scalars['String']>;
  recipients?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** ISO-8601 */
  scheduledDelivery?: InputMaybe<Scalars['DateTime']>;
  /** ISO-8601 */
  scheduledExpiration?: InputMaybe<Scalars['DateTime']>;
  statusRecipients?: InputMaybe<Array<InputMaybe<StatusRecipientInput>>>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MessageType>;
};

export enum MessageStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  STOPPED = 'STOPPED'
}

export enum MessageType {
  INBOX = 'INBOX',
  INBOX_PUSH = 'INBOX_PUSH',
  NOTICE = 'NOTICE'
}

export type Meter = {
  activeContractId?: Maybe<Scalars['String']>;
  activeOrPendingContract?: Maybe<Array<Maybe<ElectricityContract>>>;
  alias?: Maybe<Scalars['String']>;
  classification?: Maybe<MeterClassification>;
  electricityContracts?: Maybe<Array<Maybe<ElectricityContract>>>;
  gridOwner?: Maybe<Scalars['String']>;
  gridOwnerGLN?: Maybe<Scalars['String']>;
  hasActiveContract: Scalars['Boolean'];
  hasActiveOrPendingContract: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  meterId?: Maybe<Scalars['String']>;
  meteringPointId?: Maybe<Scalars['String']>;
  owner?: Maybe<MeterOwner>;
  priceZone?: Maybe<ElectricityPriceZone>;
  processingEvents?: Maybe<LatestProcessingEvents>;
  scaling?: Maybe<MeterScaling>;
};

export type MeterClassification = {
  classification?: Maybe<ClassificationType>;
  consumptionCode?: Maybe<Scalars['String']>;
  consumptionDescription?: Maybe<Scalars['String']>;
  corporationCode?: Maybe<Scalars['String']>;
  corporationDescription?: Maybe<Scalars['String']>;
  possibleCostSupport: Scalars['Boolean'];
};

export type MeterOwner = {
  nameOfOwner?: Maybe<Scalars['String']>;
  type?: Maybe<OwnerType>;
};

export type MeterScaling = {
  factor?: Maybe<Scalars['Float']>;
  /** ISO-8601 */
  fromDate?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  validated?: Maybe<Scalars['DateTime']>;
};

/** Mutation root */
export type Mutation = {
  /** Add user to group */
  addUserToGroup?: Maybe<User>;
  bulkFixPushNotificationTokens?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  createContentText?: Maybe<ContentText>;
  createGridOwnerMapping: GridOwnerMapping;
  /** Create OTP for user testing */
  createUserTestOTP?: Maybe<Scalars['String']>;
  /** Create token (temporary accessToken only) for user testing */
  createUserTestToken?: Maybe<UserTestToken>;
  deleteABTest?: Maybe<AbTest>;
  deleteContent?: Maybe<Content>;
  deleteFeatureFlag?: Maybe<FeatureFlag>;
  deleteGroup?: Maybe<Group>;
  deleteHelpPage?: Maybe<HelpPage>;
  deleteNotificationTemplate?: Maybe<NotificationTemplate>;
  deleteServiceMessage?: Maybe<ServiceMessage>;
  fixPushNotificationToken?: Maybe<Scalars['Boolean']>;
  manualTriggerSporpticeNotifications: Scalars['Boolean'];
  /** Removes all active refresh tokens on that user. */
  removeActiveUserRefreshTokens?: Maybe<Scalars['Void']>;
  removeFjuttFromUser: Scalars['Boolean'];
  /** Remove user to group */
  removeUserFromGroup?: Maybe<User>;
  setupAndReturnUserFromUc?: Maybe<User>;
  syncContractsForListOfAccountingPoints: Scalars['Boolean'];
  syncUsers?: Maybe<Array<Maybe<SyncUserResponse>>>;
  syncronizeGridOwner: Scalars['Boolean'];
  syncronizeGridOwners: Scalars['Boolean'];
  updateContentText?: Maybe<ContentText>;
  updateGridOwnerMapping: GridOwnerMapping;
  /** Oppdaterer bruker sitt telefonnummer og fødselsdato */
  updatePhonenumberAndOrBirthDate: Scalars['Boolean'];
  updateUserContracts: Scalars['Boolean'];
  upsertABTest?: Maybe<AbTest>;
  upsertContent?: Maybe<Content>;
  upsertFeatureFlag?: Maybe<FeatureFlag>;
  upsertGroup?: Maybe<Group>;
  upsertHelpPage?: Maybe<HelpPage>;
  upsertLabelSegment?: Maybe<LabelSegment>;
  /** Save or update message */
  upsertMessage?: Maybe<Message>;
  upsertNotificationTemplate?: Maybe<NotificationTemplate>;
  upsertServiceMessage?: Maybe<ServiceMessage>;
  upsertSpotpriceZone?: Maybe<SpotpriceZone>;
};


/** Mutation root */
export type MutationAddUserToGroupArgs = {
  groupId: Scalars['String'];
  userId: Scalars['String'];
};


/** Mutation root */
export type MutationBulkFixPushNotificationTokensArgs = {
  pushNotificationTokens?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Mutation root */
export type MutationCreateContentTextArgs = {
  content?: InputMaybe<ContentTextInput>;
};


/** Mutation root */
export type MutationCreateGridOwnerMappingArgs = {
  ecomonId: Scalars['String'];
  mergedCompanies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  syncronizeNow?: InputMaybe<Scalars['Boolean']>;
  websiteURL: Scalars['String'];
};


/** Mutation root */
export type MutationCreateUserTestOtpArgs = {
  userId: Scalars['String'];
};


/** Mutation root */
export type MutationCreateUserTestTokenArgs = {
  userId: Scalars['String'];
};


/** Mutation root */
export type MutationDeleteAbTestArgs = {
  abTestId: Scalars['String'];
};


/** Mutation root */
export type MutationDeleteContentArgs = {
  contentId: Scalars['String'];
};


/** Mutation root */
export type MutationDeleteFeatureFlagArgs = {
  featureFlagId: Scalars['String'];
};


/** Mutation root */
export type MutationDeleteGroupArgs = {
  groupId: Scalars['String'];
};


/** Mutation root */
export type MutationDeleteHelpPageArgs = {
  helpPageId: Scalars['String'];
};


/** Mutation root */
export type MutationDeleteNotificationTemplateArgs = {
  notificationTemplateId: Scalars['String'];
};


/** Mutation root */
export type MutationDeleteServiceMessageArgs = {
  serviceMessageId: Scalars['String'];
};


/** Mutation root */
export type MutationFixPushNotificationTokenArgs = {
  pushNotificationToken?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationManualTriggerSporpticeNotificationsArgs = {
  date?: InputMaybe<Scalars['Date']>;
};


/** Mutation root */
export type MutationRemoveActiveUserRefreshTokensArgs = {
  userId: Scalars['String'];
};


/** Mutation root */
export type MutationRemoveFjuttFromUserArgs = {
  fjuttId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationRemoveUserFromGroupArgs = {
  groupId: Scalars['String'];
  userId: Scalars['String'];
};


/** Mutation root */
export type MutationSetupAndReturnUserFromUcArgs = {
  birthDate?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSyncContractsForListOfAccountingPointsArgs = {
  accountingPointsCSV?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSyncUsersArgs = {
  userIds: Array<InputMaybe<Scalars['String']>>;
};


/** Mutation root */
export type MutationSyncronizeGridOwnerArgs = {
  mappingId: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateContentTextArgs = {
  contentText?: InputMaybe<ContentTextInput>;
};


/** Mutation root */
export type MutationUpdateGridOwnerMappingArgs = {
  mapping: GridOwnerMappingInput;
  syncronizeNow?: InputMaybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationUpdatePhonenumberAndOrBirthDateArgs = {
  birthDate?: InputMaybe<Scalars['Date']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationUpdateUserContractsArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationUpsertAbTestArgs = {
  abTest: AbTestInput;
};


/** Mutation root */
export type MutationUpsertContentArgs = {
  content: ContentInput;
};


/** Mutation root */
export type MutationUpsertFeatureFlagArgs = {
  featureFlag: FeatureFlagInput;
};


/** Mutation root */
export type MutationUpsertGroupArgs = {
  group: GroupInput;
};


/** Mutation root */
export type MutationUpsertHelpPageArgs = {
  helpPage: HelpPageInput;
};


/** Mutation root */
export type MutationUpsertLabelSegmentArgs = {
  labelSegment: LabelSegmentInput;
};


/** Mutation root */
export type MutationUpsertMessageArgs = {
  action?: InputMaybe<Action>;
  message: MessageInput;
};


/** Mutation root */
export type MutationUpsertNotificationTemplateArgs = {
  notificationTemplate: NotificationTemplateInput;
};


/** Mutation root */
export type MutationUpsertServiceMessageArgs = {
  serviceMessage: ServiceMessageInput;
};


/** Mutation root */
export type MutationUpsertSpotpriceZoneArgs = {
  spotpriceZone?: InputMaybe<SpotpriceZoneInput>;
};

export enum NordpoolArea {
  NO1 = 'NO1',
  NO2 = 'NO2',
  NO3 = 'NO3',
  NO4 = 'NO4',
  NO5 = 'NO5'
}

export type Notification = {
  content?: Maybe<Scalars['String']>;
  feedLink?: Maybe<FeedItemLink>;
  hasInAppView?: Maybe<Scalars['Boolean']>;
  hasInboxElement?: Maybe<Scalars['Boolean']>;
  hasPushNotification?: Maybe<Scalars['Boolean']>;
  hasReceivedPushNotification?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
  notificationId?: Maybe<Scalars['String']>;
  notificationTemplateId?: Maybe<Scalars['String']>;
  pushNotificationData?: Maybe<Array<Maybe<Entry_String_String>>>;
  /** ISO-8601 */
  pushNotificationInteractedWithAt?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  pushNotificationReceivedAt?: Maybe<Scalars['DateTime']>;
  pushNotificationReferences?: Maybe<Array<Maybe<PushNotificationStorage>>>;
  pushNotificationStatus?: Maybe<NotificationStatus>;
  referenceId?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  sentAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<NotificationType>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};


export type NotificationPushNotificationDataArgs = {
  key?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NotificationAggregate = {
  _id?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['BigInteger']>;
  notification?: Maybe<Notification>;
};

export type NotificationRule = {
  name?: Maybe<Scalars['String']>;
};

export enum NotificationStatus {
  DISSMISSED = 'DISSMISSED',
  INTERACTED_WITH = 'INTERACTED_WITH',
  RECEIVED = 'RECEIVED',
  SENDING_ERROR = 'SENDING_ERROR',
  SENDING_ERROR_CAUSE_HANDLED = 'SENDING_ERROR_CAUSE_HANDLED',
  SENT = 'SENT'
}

export type NotificationSubscription = {
  id?: Maybe<Scalars['String']>;
  rule?: Maybe<NotificationRule>;
  type?: Maybe<NotificationType>;
  userId?: Maybe<Scalars['String']>;
};

export type NotificationTemplate = {
  content?: Maybe<Scalars['String']>;
  hasBeenSent?: Maybe<Scalars['Boolean']>;
  hasInAppView?: Maybe<Scalars['Boolean']>;
  hasInboxElement?: Maybe<Scalars['Boolean']>;
  hasPushNotification?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  sentDate?: Maybe<Scalars['Date']>;
  sentNotificationsCount?: Maybe<Scalars['BigInteger']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<NotificationType>;
};

export type NotificationTemplateInput = {
  content?: InputMaybe<Scalars['String']>;
  hasBeenSent?: InputMaybe<Scalars['Boolean']>;
  hasInAppView?: InputMaybe<Scalars['Boolean']>;
  hasInboxElement?: InputMaybe<Scalars['Boolean']>;
  hasPushNotification?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  /** ISO-8601 */
  sentDate?: InputMaybe<Scalars['Date']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<NotificationType>;
};

export enum NotificationType {
  LINK = 'LINK',
  SHARING = 'SHARING',
  SPOTPRICE = 'SPOTPRICE'
}

export enum OwnerType {
  COMPANY = 'COMPANY',
  OWNER_PRIVATE = 'OWNER_PRIVATE',
  SHARED_COMPANY = 'SHARED_COMPANY',
  SHARED_HIDDEN = 'SHARED_HIDDEN',
  SHARED_PRIVATE = 'SHARED_PRIVATE',
  UNKNOWN = 'UNKNOWN'
}

export type PaginationResponse_Group = {
  items?: Maybe<Array<Maybe<Group>>>;
  total?: Maybe<Scalars['BigInteger']>;
};

export type PaginationResponse_NotificationTemplate = {
  items?: Maybe<Array<Maybe<NotificationTemplate>>>;
  total?: Maybe<Scalars['BigInteger']>;
};

export enum PriceCalculationType {
  delayedMonthly = 'delayedMonthly',
  standardDaily = 'standardDaily'
}

export type ProcessingEvent = {
  filename?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  messageSequenceId: Scalars['BigInteger'];
  meteringPointId?: Maybe<Scalars['String']>;
  status?: Maybe<ProcessingStatus>;
  /** ISO-8601 */
  timestamp?: Maybe<Scalars['DateTime']>;
};

export enum ProcessingStatus {
  DEFERED = 'DEFERED',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS'
}

export type Product = {
  code?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  from?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  priceCalculationType?: Maybe<PriceCalculationType>;
  primary?: Maybe<Scalars['Boolean']>;
  /** ISO-8601 */
  to?: Maybe<Scalars['Date']>;
};

export type PushNotificationStorage = {
  referende?: Maybe<Scalars['String']>;
  status?: Maybe<NotificationStatus>;
};

/** Query root */
export type Query = {
  aBTest?: Maybe<AbTest>;
  aBTests?: Maybe<Array<Maybe<AbTest>>>;
  allContentTexts?: Maybe<Array<Maybe<ContentText>>>;
  availableModels: Array<Maybe<Scalars['String']>>;
  companyAccountCountLast30Days?: Maybe<CompanyAccountCount>;
  countUsersWithSegments?: Maybe<CountUsersWithSegmentsResponse>;
  distributionModelSupport: GridOwnerSummary;
  failedEvents?: Maybe<Array<Maybe<GroupedMeteringValue>>>;
  featureFlag?: Maybe<FeatureFlag>;
  featureFlags?: Maybe<Array<Maybe<FeatureFlag>>>;
  featureFlagsByUserId?: Maybe<Array<Maybe<FeatureFlag>>>;
  fileRecieptStatus?: Maybe<Array<Maybe<FileRecieptStatus>>>;
  gridOwnerMapping?: Maybe<GridOwnerMapping>;
  gridOwnerMappings: Array<Maybe<GridOwnerMapping>>;
  gridOwners: Array<Maybe<GridOwnerUsersCount>>;
  group?: Maybe<Group>;
  groups?: Maybe<Array<Maybe<Group>>>;
  groupsByUserId?: Maybe<Array<Maybe<Group>>>;
  infoScreenData?: Maybe<InfoScreenData>;
  labelSegments?: Maybe<Array<Maybe<LabelSegment>>>;
  message?: Maybe<Message>;
  /** List messages */
  messages?: Maybe<Array<Maybe<Message>>>;
  /** Gets number of metering value- and cash flow files recieved from UC for the current day. */
  meteringValueFileStats?: Maybe<Array<Maybe<GroupedMeteringValue>>>;
  meteringValueHistory?: Maybe<Array<Maybe<Entry_String_DateTime>>>;
  notificationSubscriptions?: Maybe<Array<Maybe<NotificationSubscription>>>;
  notificationTemplates?: Maybe<PaginationResponse_NotificationTemplate>;
  notifications?: Maybe<CursorPaginationResponse_Notification>;
  paginateContent?: Maybe<CursorPaginationResponse_Content>;
  /** Paginate groups */
  paginateGroups?: Maybe<PaginationResponse_Group>;
  paginateHelpPages?: Maybe<CursorPaginationResponse_HelpPage>;
  paginateServiceMessages?: Maybe<CursorPaginationResponse_ServiceMessage>;
  /** Paginate users */
  paginatedUsers?: Maybe<CursorPaginationResponse_User>;
  searchContentText?: Maybe<ContentText>;
  segments?: Maybe<Array<Maybe<Segment>>>;
  singleContent?: Maybe<Content>;
  singleHelpPage?: Maybe<HelpPage>;
  singleLabelSegment?: Maybe<LabelSegment>;
  singleNotificationTemplate?: Maybe<NotificationTemplate>;
  singleServiceMessage?: Maybe<ServiceMessage>;
  singleSpotpriceZone?: Maybe<SpotpriceZone>;
  spotpriceZones?: Maybe<Array<Maybe<SpotpriceZone>>>;
  statistics?: Maybe<Statistics>;
  statisticsForGroup?: Maybe<Array<Maybe<StatisticsItem>>>;
  /** Gets the total number of active contracts right now. */
  totalActiveContracts?: Maybe<Scalars['Float']>;
  uniqueNotifications?: Maybe<Array<Maybe<NotificationAggregate>>>;
  /** Get user by id */
  user?: Maybe<User>;
  /** List all users */
  users?: Maybe<Array<Maybe<User>>>;
};


/** Query root */
export type QueryAbTestArgs = {
  id: Scalars['String'];
};


/** Query root */
export type QueryAvailableModelsArgs = {
  showAll?: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryCountUsersWithSegmentsArgs = {
  segmentIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  segmentNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Query root */
export type QueryDistributionModelSupportArgs = {
  ecomonId?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryFeatureFlagArgs = {
  id: Scalars['String'];
};


/** Query root */
export type QueryFeatureFlagsByUserIdArgs = {
  userId: Scalars['String'];
};


/** Query root */
export type QueryGridOwnerMappingArgs = {
  id: Scalars['String'];
};


/** Query root */
export type QueryGridOwnersArgs = {
  showAll?: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryGroupArgs = {
  groupId: Scalars['String'];
};


/** Query root */
export type QueryGroupsByUserIdArgs = {
  userId: Scalars['String'];
};


/** Query root */
export type QueryInfoScreenDataArgs = {
  token?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryMessageArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryMeteringValueHistoryArgs = {
  key?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Query root */
export type QueryNotificationSubscriptionsArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryNotificationTemplatesArgs = {
  count?: InputMaybe<Scalars['Int']>;
  startIndex?: InputMaybe<Scalars['Int']>;
};


/** Query root */
export type QueryNotificationsArgs = {
  count?: InputMaybe<Scalars['Int']>;
  notificationTemplateId?: InputMaybe<Scalars['String']>;
  pushStatus?: InputMaybe<NotificationStatus>;
  startIndex?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryPaginateContentArgs = {
  count?: InputMaybe<Scalars['Int']>;
  startIndex?: InputMaybe<Scalars['Int']>;
};


/** Query root */
export type QueryPaginateGroupsArgs = {
  count?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  startIndex?: InputMaybe<Scalars['Int']>;
};


/** Query root */
export type QueryPaginateHelpPagesArgs = {
  count?: InputMaybe<Scalars['Int']>;
  startIndex?: InputMaybe<Scalars['Int']>;
};


/** Query root */
export type QueryPaginateServiceMessagesArgs = {
  count?: InputMaybe<Scalars['Int']>;
  startIndex?: InputMaybe<Scalars['Int']>;
};


/** Query root */
export type QueryPaginatedUsersArgs = {
  count?: InputMaybe<Scalars['Int']>;
  deviceLastSeenAfter?: InputMaybe<Scalars['DateTime']>;
  devicePlatform?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  startIndex?: InputMaybe<Scalars['Int']>;
};


/** Query root */
export type QuerySearchContentTextArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QuerySingleContentArgs = {
  contentId: Scalars['String'];
};


/** Query root */
export type QuerySingleHelpPageArgs = {
  helpPageId: Scalars['String'];
};


/** Query root */
export type QuerySingleLabelSegmentArgs = {
  labelSegmentId: Scalars['String'];
};


/** Query root */
export type QuerySingleNotificationTemplateArgs = {
  notificationTemplateId?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QuerySingleServiceMessageArgs = {
  serviceMessageId: Scalars['String'];
};


/** Query root */
export type QuerySingleSpotpriceZoneArgs = {
  spotpriceZoneId: Scalars['String'];
};


/** Query root */
export type QueryStatisticsForGroupArgs = {
  group?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryUserArgs = {
  userId: Scalars['String'];
};


/** Query root */
export type QueryUsersArgs = {
  count?: InputMaybe<Scalars['Int']>;
  startIndex?: InputMaybe<Scalars['Int']>;
};

export type Residence = {
  activeContractId?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  alias?: Maybe<Scalars['String']>;
  broadbandList?: Maybe<Array<Maybe<Broadband>>>;
  hasActiveContract: Scalars['Boolean'];
  hasActiveOrPendingContract: Scalars['Boolean'];
  meters?: Maybe<Array<Maybe<Meter>>>;
  residenceId?: Maybe<Scalars['String']>;
};

export type SanitizedToken = {
  accessTokenExpiration?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  issued?: Maybe<Scalars['String']>;
  refreshTokenExpiration?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type Segment = {
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ServiceMessage = {
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  location?: Maybe<Array<Maybe<ServiceMessageLocation>>>;
  /** ISO-8601 */
  publishDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  segmentIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  segmentNotIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
};

export type ServiceMessageInput = {
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Array<InputMaybe<ServiceMessageLocation>>>;
  /** ISO-8601 */
  publishDate?: InputMaybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  segmentIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  segmentNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
};

export enum ServiceMessageLocation {
  FORBRUK = 'FORBRUK',
  GLOBAL = 'GLOBAL',
  HJEM = 'HJEM',
  KOSTNAD = 'KOSTNAD',
  LOGIN = 'LOGIN',
  MEG = 'MEG',
  MEG_BOLIGER_OG_AVTALER = 'MEG_BOLIGER_OG_AVTALER',
  MEG_FAKTURA = 'MEG_FAKTURA',
  MEG_FAKTURAOVERSIKT = 'MEG_FAKTURAOVERSIKT',
  MEG_TILGANGER = 'MEG_TILGANGER',
  MEG_VERVING = 'MEG_VERVING',
  NYTT = 'NYTT',
  SPOTPRIS = 'SPOTPRIS',
  TJENESTER = 'TJENESTER'
}

export type SpotPriceNotificationRule = NotificationRule & {
  level?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  operator: SpotpriceLevelOperator;
  zone: Scalars['String'];
};

export enum SpotpriceLevelOperator {
  OVER = 'OVER',
  UNDER = 'UNDER'
}

export type SpotpriceZone = {
  highPrice?: Maybe<Scalars['Float']>;
  id?: Maybe<NordpoolArea>;
  lowPrice?: Maybe<Scalars['Float']>;
  notifyOver?: Maybe<Scalars['Float']>;
  notifyUnder?: Maybe<Scalars['Float']>;
};

export type SpotpriceZoneInput = {
  highPrice?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<NordpoolArea>;
  lowPrice?: InputMaybe<Scalars['Float']>;
  notifyOver?: InputMaybe<Scalars['Float']>;
  notifyUnder?: InputMaybe<Scalars['Float']>;
};

export type Statistics = {
  hasVarsel: Scalars['Int'];
  haveHadVarsel: Scalars['Int'];
  totalVarsel: Scalars['Int'];
  withoutVarsel: Scalars['Int'];
};

export type StatisticsItem = {
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  updated?: Maybe<Scalars['DateTime']>;
  values?: Maybe<Array<Maybe<Entry_String_Float>>>;
};


export type StatisticsItemValuesArgs = {
  key?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum Status {
  HIDDEN = 'HIDDEN',
  READ = 'READ',
  UNREAD = 'UNREAD'
}

export type StatusRecipient = {
  status?: Maybe<Status>;
  userId?: Maybe<Scalars['String']>;
};

export type StatusRecipientInput = {
  status?: InputMaybe<Status>;
  userId?: InputMaybe<Scalars['String']>;
};

export type SyncUserResponse = {
  success: Scalars['Boolean'];
  userId?: Maybe<Scalars['String']>;
};

export type User = {
  acceptedTermsAndConditions?: Maybe<Array<Maybe<AcceptedTermsAndConditions>>>;
  /** ISO-8601 */
  birthDate?: Maybe<Scalars['Date']>;
  /** ISO-8601 */
  created?: Maybe<Scalars['DateTime']>;
  customerIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dashboards?: Maybe<Array<Maybe<Dashboard>>>;
  defaultAddress?: Maybe<DefaultAddress>;
  devices?: Maybe<Array<Maybe<UserDeviceTransport>>>;
  disableSync: Scalars['Boolean'];
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fjuttEffektVarsel?: Maybe<Array<Maybe<UserFjuttEffektVarsel>>>;
  fjutter?: Maybe<Array<Maybe<Fjutt>>>;
  groupIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  groups?: Maybe<Array<Maybe<Group>>>;
  harkToken?: Maybe<HarkToken>;
  hasActiveContract: Scalars['Boolean'];
  id?: Maybe<Scalars['String']>;
  intercom?: Maybe<Intercom>;
  lastName?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  lastUpdated?: Maybe<Scalars['DateTime']>;
  mobileNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  ownedContracts?: Maybe<Array<Maybe<Contract>>>;
  residences?: Maybe<Array<Maybe<Residence>>>;
  sharedContracts?: Maybe<Array<Maybe<Contract>>>;
  trackingId?: Maybe<Scalars['String']>;
  ucCustomerGuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  userId?: Maybe<Scalars['String']>;
};

export type UserDeviceTransport = {
  appVersion?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
  /** ISO-8601 */
  lastSeen?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  operatingSystem?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  pushNotificationToken?: Maybe<Scalars['String']>;
  tokens?: Maybe<Array<Maybe<SanitizedToken>>>;
  userId?: Maybe<Scalars['String']>;
};

export type UserFjuttEffektVarsel = {
  deviceId?: Maybe<Scalars['String']>;
  harkAlertSettingId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  limitInWatts: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  scaling?: Maybe<Scalars['Float']>;
};

export type UserTestToken = {
  accessToken?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  accessTokenExpiration?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 */
  issued?: Maybe<Scalars['DateTime']>;
};

export enum ViewType {
  EXTERNAL = 'EXTERNAL',
  MODAL = 'MODAL',
  POPOVER = 'POPOVER',
  ROUTER = 'ROUTER',
  STACK = 'STACK'
}
