import {ReactNode, useEffect, useRef, useState} from "react";
import {Link, LinkProps, Outlet} from "react-router-dom";
import styled, {css} from "styled-components";

import {
    faChartPieSimple,
    faCircleP,
    faCog,
    faFlag,
    faNewspaper,
    faPeopleGroup,
    faTriangleExclamation
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {Direction, Flex, FlexFullWidth} from "components/atoms/Flex";
import {SecondTitle, Title} from "components/atoms/Typography";
import Footer from "components/molecules/Footer";
import MenuItem from "components/organisms/MenuItem";
import useAccess from "hooks/useAccess";
import MenuItemGroup from "./MenuItemGroup";
import MenuItemSmall from "./MenuItemSmall";

const LayoutWrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
`;

export const PageWrapperElement = styled(Flex)`
    padding: var(--grid-4);
    width: calc(100% - 300px);
`;

const Sidebar = styled(Flex)`
    min-height: calc(100vh - 100px);
    width: 280px;
    border-right: 1px solid var(--nte-graa-lys);
    background-color: #0079AD;
    gap: 1rem;
`;

const MenuItems = styled.div<{ topHeight?: number }>`
    display: flex;
    flex-direction: column;
    height: max-content;
    position: sticky;
    top: ${(props) => (props?.topHeight ? `${props.topHeight}px` : 0)};
`;

const Logo = styled.img`
    height: 35px;
    margin-right: -11px;
`;

const StyledLink = styled(Link)``;

function NoAccess() {
    return <PageWrapperElement grow="0" shrink="0" direction={Direction.column}>
        <h1 style={{ marginBottom: "10px"}}>Ingen tilgang</h1>
        <p>Du har ikke tilgang til denne siden.<br/>
        Vennligst ta kontakt med vårt servicesenter hvis du mener dette er feil.</p>
    </PageWrapperElement>;
}

export default function MainLayout() {
    const access = useAccess();
    const menuRef = useRef<HTMLDivElement>(null);
    const [menuListTopHeight, setMenuListTopHeight] = useState<number>(0);

    useEffect(() => {
        function calculateTopHeight() {
            if (menuRef.current) {
                const windowHeight = window.innerHeight;
                const listHeight = menuRef.current.clientHeight;
                const newCalculatedHeight = windowHeight - listHeight;
                if (newCalculatedHeight < 0 && menuListTopHeight !== newCalculatedHeight) {
                    setMenuListTopHeight(newCalculatedHeight);
                } else if (menuListTopHeight !== 0) {
                    setMenuListTopHeight(0);
                }
            }
        }

        window.addEventListener("resize", calculateTopHeight);

        calculateTopHeight();

        return () => window.removeEventListener("resize", calculateTopHeight);
    }, []);

    return (
        <LayoutWrapper>
            <FlexFullWidth>
                <Sidebar direction={Direction.column} grow="0" shrink="0">
                    <Link style={{padding: "24px 24px 0"}} to="/" title="Gå til forsiden">
                        <Logo src="assets/icons/nte-logo-white.svg" alt="NTE Logo"/>
                    </Link>
                    {(access.length > 0) && (
                        <MenuItems ref={menuRef} topHeight={menuListTopHeight}>
                            <MenuItem to="/users" label="Brukere"
                                      icon={<FontAwesomeIcon width="30px" icon={faPeopleGroup}/>}/>
                            {["Admin", "FeedCards"].some(role => access.includes(role)) && (
                                <MenuItemGroup title="Innhold" icon={<FontAwesomeIcon icon={faNewspaper}/>}>
                                    <MenuItemSmall to="/content" label="Feedkort"/>
                                    <MenuItemSmall to="/help-pages" label="Hjelpesider" />
									<MenuItemSmall to="/contentblocks" label="Tekstblokker"/>
                                </MenuItemGroup>
                            )}
                            {access.includes("Admin") && (
                                <MenuItemGroup title="Features" icon={<FontAwesomeIcon icon={faFlag}/>}>
                                    <MenuItemSmall to="/features" label="Feature flags"/>
                                    <MenuItemSmall to="/groups" label="Grupper"/>
                                    <MenuItemSmall to="/ab-tests" label="A/B Tester"/>
                                </MenuItemGroup>
                            )}
                            <MenuItemGroup title="Konfigurasjon" icon={<FontAwesomeIcon icon={faCog}/>}>
                                {access.includes("Admin") &&
                                    <MenuItemSmall to="/spotprice-areas" label="Spotprisområder"/>}
                                <MenuItemSmall to="/grid-templates" label="Netteiere"/>
                                <MenuItemSmall to="/intercom-segments" label="Intercom segmenter"/>
                            </MenuItemGroup>
                            {access.includes("Admin") && (
                                <>
                                    <MenuItem to="/service-messages" label="Driftsmeldinger"
                                              icon={<FontAwesomeIcon width="30px" icon={faTriangleExclamation}/>}/>
                                    <MenuItem to="/stats" label="Statistikk"
                                              icon={<FontAwesomeIcon icon={faChartPieSimple}/>}/>
                                </>
                            )}
                            {["Admin", "Product"].some(role => access.includes(role)) && (
                                <MenuItem to="/products" label="Produktadmin"
                                          icon={<FontAwesomeIcon icon={faCircleP}/>}/>
                            )}
                        </MenuItems>
                    )}
                </Sidebar>

                {(access.length > 0)
                    ? <PageWrapperElement grow="0" shrink="0" direction={Direction.column}>
                        <Outlet/>
                        <Footer/>
                    </PageWrapperElement>
                    : <NoAccess/>
                }
            </FlexFullWidth>
        </LayoutWrapper>
    );
}

export const Intro = styled.h2`
    margin-bottom: var(--grid-2);
    text-align: center;
    ${(props) =>
            props.theme.media.desktop(css`
                font-size: 20px;
                margin-bottom: 5px;
            `)}
`;

export function MainLayoutPageHeader(
    {
        title,
        intro,
        link,
        loading,
    }: Readonly<{
        title?: ReactNode;
        intro?: ReactNode;
        link?: LinkProps & { label: ReactNode };
        loading?: boolean;
    }>
) {
    return (
        <HeaderModalWrapper gap="6px" direction={Direction.column} style={{marginBottom: "10px"}}>
            {title && <Title loading={loading}>{title}</Title>}
            {intro && <SecondTitle loading={loading}>{intro}</SecondTitle>}
            {link && <StyledLink {...link}>{link.label}</StyledLink>}
        </HeaderModalWrapper>
    );
}

const HeaderModalWrapper = styled(Flex)`
`;