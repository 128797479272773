import {Direction, Flex} from "components/atoms/Flex";
import {InfoColumn, InfoRow} from "components/atoms/InfoRow";
import {MainLayoutPageHeader} from "components/organisms/MainLayout";

import {usePaginateIntercomSegmentsQuery} from "./IntercomSegmentsPage.graphql-gen";
import {InfoColumnLabel} from "pages/Users/EditUserPage";
import Loading from "components/molecules/Loading";
import Error from "components/molecules/Error";
import {useNavigate} from "react-router-dom";

export default function IntercomSegmentsPage() {
    const navigate = useNavigate();
    const {data, loading, error} = usePaginateIntercomSegmentsQuery();

    return (
        <Flex direction={Direction.column} gap="calc( var(--grid-2) * 2)">
            <MainLayoutPageHeader
                title={
                    <>
                        Intercom-segmenter
                    </>
                }
                intro="Alle Intercom segmenter"
            />
            <Flex direction={Direction.column} style={{borderRadius: "15px", overflow: "hidden"}}>
                {error && <Error errorMessage="Feil ved henting av Intercom segmenter"/>}
                {loading && <Loading/>}
                {!loading && data?.labelSegments?.map((labelSegment) =>
                    <InfoRow key={labelSegment.id} onClick={() => {
                        navigate("/intercom-segments/" + labelSegment.id);
                    }} interactive>
                        <InfoColumn width="50%">
                            <InfoColumnLabel>{labelSegment.label}</InfoColumnLabel>
                        </InfoColumn>
                        {labelSegment.numberOfSegments > 0 &&
                            <InfoColumn width="50%" style={{textAlign: "right", color: "green"}}>
                                <InfoColumnLabel>Koblet til {labelSegment.numberOfSegments} segmenter</InfoColumnLabel>
                            </InfoColumn>}
                    </InfoRow>)}
            </Flex>
        </Flex>
    );
}
