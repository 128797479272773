import {Link, useNavigate} from "react-router-dom";

import {faPlusCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {Direction, Flex} from "components/atoms/Flex";
import {InfoColumn, InfoRow} from "components/atoms/InfoRow";
import TrafficLightBadge from "components/molecules/TrafficLightBadge";
import {MainLayoutPageHeader} from "components/organisms/MainLayout";
import useDateTime from "hooks/useDateTime";
import {InfoColumnValue} from "pages/Users/EditUserPage";
import Error from "components/molecules/Error";

import Loading from "components/molecules/Loading";
import { useHelpPagesListPageQuery } from "./HelpPagesListPage.graphql-gen";

export default function HelpPagesListPage() {
	const navigate = useNavigate();
	const date = useDateTime();
	const { data, error, loading } = useHelpPagesListPageQuery();

	return (
			<>
				<MainLayoutPageHeader
					title={
						<Flex gap="var(--grid-2)">
							Hjelpesider
							<Link to="create" title="Nyt hjelpeside">
								<FontAwesomeIcon icon={faPlusCircle} />
							</Link>
						</Flex>
					}
				/>
				<Flex style={{ marginTop: "40px", borderRadius: "15px", overflow: "hidden" }} direction={Direction.column}>
					{(error && !loading) && <Error errorMessage="Feil ved henting av data" />}
					{(loading && !data) && <Loading />}
					{data?.paginateHelpPages?.items
						?.slice()
						.map((content) => (
							<InfoRow
								key={content.id}
								interactive
								onClick={() => {
									navigate("/help-pages/" + content.id);
								}}
							>
								<InfoColumn width="10em">
									<InfoColumnValue>
										<TrafficLightBadge green={content?.published} red={!content?.published}>
											<>{content?.published ? "Publisert" : "Ikke publisert"}</>
										</TrafficLightBadge>
									</InfoColumnValue>
								</InfoColumn>
								<InfoColumn>
									<InfoColumnValue>
										{content?.title}
									</InfoColumnValue>
								</InfoColumn>
							</InfoRow>
						))}
				</Flex>
			</>
	);
}
