import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import {
	faArrowRotateRight,
	faBan,
	faCircleCheck,
	faLink,
	faLoader,
	faRightFromBracket,
	faRotate,
	faTrash,
	faUpRightFromSquare,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { WarningBadge } from "components/atoms/Badge";
import { FlexColumn, FlexRow } from "components/atoms/FlexRow";
import { InfoColumn, InfoColumnValue, InfoRow } from "components/atoms/InfoRow";
import Accordion from "components/molecules/Accordion";
import TextCopy from "components/molecules/TextCopy";
import TrafficLightBadge from "components/molecules/TrafficLightBadge";
import ActiveBadge from "components/organisms/ActiveBadge";
import useDateTime from "hooks/useDateTime";
import { useGetGridOwnersQuery } from "pages/GridOwnerMapping/GridOwnerMappingsPage/GridOwnerMappingsPageUnmappedTab.graphql-gen";
import {
	GetUserDocument,
	GetUserQuery,
	useCreateUserTestOtpMutation,
	useInvalidateRefreshTokensOnUserMutation,
	useUpdateUserContractsMutation,
} from "pages/Users/EditUserPage.graphql-gen";
import { contractStatusDisplayName, meterHasActiveContract, residenceHasActiveContract } from "utils/Contract";
import { spotpriceAreaDisplayName } from "utils/SpotpriceArea";
import {getUcBaseLink, getUcEventconsumerBaseLink, getUcMeteringvaluesBaseLink, isDevEnv} from "utils/Utils";

import { ContractStatus, ElectricityContract, Meter, ProcessingStatus, Residence } from "generated/graphql-types";

import Modal from "../../../components/molecules/Modal";
import { useRemoveFjuttFromUserMutation } from "./EditUserPageInfoTab.graphql-gen";
import { useSyncUsersMutation } from "./EditUserPageNotificationsTab.graphql-gen";

function formatPhoneNumberForTest(phoneNumber: string | undefined) {
	if (!phoneNumber) return "";
	if (isDevEnv()) {
		return "test-" + (phoneNumber.length > 8 ? phoneNumber.slice(-8) : phoneNumber);
	}
	return phoneNumber;
}

export default function EditUserPageInfoTab({
	loading,
	user,
	userId,
	showAppToken,
	setShowAppToken,
	createUserTestToken,
}: {
	loading: boolean;
	user: GetUserQuery["user"];
	userId: any;
	showAppToken: any;
	setShowAppToken: any;
	createUserTestToken: any;
}) {
	const { format } = useDateTime();
	const [syncUsers, { loading: syncUsersLoading }] = useSyncUsersMutation();

	const [fraktalModalData, setFraktalModalData] = useState<null | { body?: string; status?: number }>(null);
	const [updateUserContracts, { loading: updateUserContractsLoading }] = useUpdateUserContractsMutation();
	const [invalidateRefreshTokens] = useInvalidateRefreshTokensOnUserMutation();

	const [otp, setOtp] = useState<string>("");
	const [createUserTestOtp] = useCreateUserTestOtpMutation({
		variables: { userId: userId },
	});

	const [removeFjutt, { loading: loadingDeleteFjutt }] = useRemoveFjuttFromUserMutation({
		refetchQueries: ["getUser"],
	});

	return (
		<>
			{fraktalModalData && (
				<Modal onClose={() => setFraktalModalData(null)}>
					<div style={{ padding: "16px" }}>
						<h2 style={{ textAlign: "center" }}>Status: {fraktalModalData.status}</h2>
						<pre>{fraktalModalData.body}</pre>
					</div>
				</Modal>
			)}
			<FlexRow>
				<FlexColumn>
					<AppButtons />
				</FlexColumn>
				<FlexColumn>
					<ActionButton
						title="Synkroniser bruker"
						disabled={syncUsersLoading || updateUserContractsLoading}
						onClick={async () => {
							updateUserContracts({
								variables: {
									userId,
								},
								refetchQueries: [GetUserDocument],
							})
								.then(async (updateContractResponse) => {
									const syncUsersResponse = await syncUsers({
										variables: {
											userIds: [userId || ""],
										},
										refetchQueries: ["getUser"],
									});
									if (!syncUsersResponse?.data?.syncUsers?.[0]?.success) {
										alert("Sync feilet...");
									}
								})
								.catch((e) => {
									console.error("Feil oppstod under sync: " + e);
								});
						}}
					>
						<FontAwesomeIcon spin={syncUsersLoading || updateUserContractsLoading} icon={faRotate} />
					</ActionButton>
					<ActionButton
						title="Logg ut bruker"
						disabled={syncUsersLoading || updateUserContractsLoading}
						onClick={async () => {
							invalidateRefreshTokens({
								variables: { userId: userId },
								refetchQueries: [GetUserDocument],
							}).catch((e) => {
								console.error("Feil oppstod under invalidering av tokens", e);
							});
						}}
					>
						<FontAwesomeIcon icon={faRightFromBracket} />
					</ActionButton>
				</FlexColumn>
				<FlexColumn>
					<b>Mobilnummer</b> <br />
					{loading ? (
						<Skeleton />
					) : (
						(
							<>
								{user?.mobileNumber} <TextCopy text={formatPhoneNumberForTest(user?.mobileNumber)} />
							</>
						) || "―"
					)}{" "}
				</FlexColumn>
				<FlexColumn>
					<b>Fødselsdato</b> <br />
					{loading ? (
						<Skeleton />
					) : (
						(
							<>
								{format(user?.birthDate, "P")} <TextCopy text={format(user?.birthDate, "ddMMyy")} />
							</>
						) || "―"
					)}
				</FlexColumn>
				<FlexColumn>
					<b>E-post</b> <br />
					{loading ? (
						<Skeleton />
					) : (
						(
							<>
								{user?.emailAddress} <TextCopy text={user?.emailAddress || ""} />
							</>
						) || "―"
					)}{" "}
				</FlexColumn>
				<FlexColumn>
					<b>Engangskode (enhetstesting)</b> <br />
					{loading ? (
						<Skeleton />
					) : (
						<>
							{otp ? (
								<>
									{otp} <TextCopy text={otp} />
								</>
							) : (
								"–"
							)}
							<GenerateOTP />
						</>
					)}{" "}
					<br />
				</FlexColumn>
			</FlexRow>

			<SectionTitle>Adresser</SectionTitle>
			{!user?.residences && <Accordion loading={loading} />}
			{user?.residences?.length === 0 && <p>Ingen adresser å vise</p>}
			{user?.residences?.length !== 0 && (
				<section>
					{user?.residences
						?.slice()
						.sort((a, b) => {
							if (!a || !b) return 0;

							const aActive = residenceHasActiveContract(a as Residence);
							const bActive = residenceHasActiveContract(b as Residence);

							if (aActive && !bActive) return -1;
							if (!aActive && bActive) return 1;

							return 0;
						})
						.map((residence, i) => (
							<ResidenceAccordion residence={residence as Residence} key={i} />
						))}
				</section>
			)}

			<SectionTitle>Eide kontrakter</SectionTitle>
			{!user?.ownedContracts && <Accordion loading={loading} />}
			{user?.ownedContracts?.length === 0 && <p>Ingen eide kontrakter å vise</p>}
			{!!user?.ownedContracts?.length && (
				<section>
					{user?.ownedContracts?.map?.((contract) => (
						<Accordion
							key={contract._id}
							header={
								<FlexRow>
									<FlexColumn>
										Kontrakt for {contract.meterPointId} {contract.product} ({contract.status})
										<a
											title="Kontraktsdetaljer i UC"
											href={`${getUcBaseLink()}/#/customer/${contract.customerGuid}/contracts/accountingpoint-details/${
												contract.accountingPointGuid
											}`}
											target="_blank"
											rel="noreferrer"
										>
											<img src="/assets/icons/uc-logo.svg" alt="Kontraktsdetaljer i UC" style={{height:"1rem", width: "auto", marginLeft: "0.5em"}}/>
										</a>
										<a
											title="Feilede eventer fra UC"
											href={`${getUcEventconsumerBaseLink()}/admin?status=DEFERED&q=${contract.customerGuid}`}
											target="_blank"
											rel="noreferrer"
										>🔎</a>
									</FlexColumn>
								</FlexRow>
							}
						/>
					))}
				</section>
			)}

			<SectionTitle>Delte kontrakter</SectionTitle>
			{!user?.sharedContracts && <Accordion loading={loading} />}
			{user?.sharedContracts?.length === 0 && <p>Ingen delte kontrakter å vise</p>}
			{!!user?.sharedContracts?.length && (
				<section>
					{user?.sharedContracts?.map?.((contract) => (
						<Accordion
							key={contract._id}
							header={
								<FlexRow>
									<FlexColumn>
										Kontrakt for {contract.meterPointId} {contract.product} ({contract.status})
										<a
											title="Kontraktsdetaljer i UC"
											href={`${getUcBaseLink()}/#/customer/${contract.customerGuid}/contracts/accountingpoint-details/${
												contract.accountingPointGuid
											}`}
											target="_blank"
											rel="noreferrer"
										>
											<img src="/assets/icons/uc-logo.svg" alt="Kontraktsdetaljer i UC" style={{ height:"1rem", width:"auto", marginLeft:"0.5em"}}/>
										</a>
									</FlexColumn>
								</FlexRow>
							}
						/>
					))}
				</section>
			)}

			<SectionTitle>Fjutter</SectionTitle>
			{!user?.fjutter && <Accordion loading={loading} />}
			{user?.fjutter?.length === 0 && <p>Ingen Fjutter å vise</p>}
			{!!user?.fjutter?.length && (
				<section>
					{user.fjutter.map((fjutt) => (
						<Accordion
							header={
								<FlexRow>
									<FlexColumn>
										Fjutt {fjutt.deviceId}{" "}
										<a
											title="Åpne Fjutt i Ecomon"
											href={`https://dashboard.ecomon.no/?deviceId=${fjutt.deviceId}`}
											target="_blank"
											rel="noreferrer"
										>
											<img src="/assets/icons/hark-logo.svg" alt="Kontraktsdetaljer i UC"
												 style={{height: "1rem", width: "auto", marginLeft: "0.5em"}}/>
										</a>
									</FlexColumn>
									<FlexColumn>
										<InfoColumnValue>Måler ID: {fjutt.anleggsId}</InfoColumnValue>
									</FlexColumn>
									<FlexColumn>
										<FontAwesomeIcon
											icon={loadingDeleteFjutt ? faLoader : faTrash}
											onClick={async (event) => {
												// eslint-disable-next-line no-restricted-globals
												if (confirm("Vil du fjerne fjutt: " + fjutt.deviceId + "?"))
													await removeFjutt({
														variables: {
															userId: userId,
															fjuttId: fjutt?.deviceId,
														},
													});
												event.stopPropagation();
											}}
										/>
									</FlexColumn>
								</FlexRow>
							}
							key={fjutt.deviceId}
						>
							<>
								{!!user.fjuttEffektVarsel &&
									user.fjuttEffektVarsel
										.filter((varsel) => varsel.deviceId === fjutt.deviceId)
										.map((varsel) => (
											<SpacedInfoRow key={varsel.deviceId} compact>
												<InfoColumn>
													<InfoColumnValue>Effektvarsel "{varsel.name}"</InfoColumnValue>
												</InfoColumn>
												<InfoColumn>
													<InfoColumnValue>Varsler ved effekt over {varsel.limitInWatts} watt</InfoColumnValue>
												</InfoColumn>
												<InfoColumn>
													Ecomon ID: {varsel.harkAlertSettingId?.substring(0, 30).concat("... ") || ""}
													<TextCopy text={varsel.harkAlertSettingId || ""} />
												</InfoColumn>
											</SpacedInfoRow>
										))}
							</>
						</Accordion>
					))}
				</section>
			)}

			<SectionTitle>Enheter</SectionTitle>
			{!user?.devices && <Accordion loading={loading} />}
			{user?.devices?.length === 0 && <p>Ingen enheter å vise</p>}
			{!!user?.devices?.length && (
				<section>
					{user.devices
						.slice()
						.sort((a, b) => {
							if (!a || !b || a.lastSeen === b.lastSeen) return 0;
							return Date.parse(a.lastSeen as string) < Date.parse(b.lastSeen as string) ? 1 : -1;
						})
						.map((device) => (
							<Accordion
								header={
									<FlexRow>
										<FlexColumn>Enhet ID: {device.deviceId}</FlexColumn>
										{device.name && <FlexColumn>Navn: {device.name}</FlexColumn>}
										{device.operatingSystem && (
											<FlexColumn>
												Device:{" "}
												{`${[device.operatingSystem, device.osVersion, device.manufacturer, device.model]
													.filter((s) => !!s)
													.join(" ")}`}
											</FlexColumn>
										)}
										{device.appVersion && <FlexColumn>App versjon: {`${device.appVersion}`}</FlexColumn>}
										{device.lastSeen && <FlexColumn>Sist sett: {format(device.lastSeen)}</FlexColumn>}
										{device?.pushNotificationToken && (
											<FlexColumn>
												Push token: {device?.pushNotificationToken?.substring(0, 30).concat("... ")}
												<TextCopy text={device.pushNotificationToken || ""} />
											</FlexColumn>
										)}
									</FlexRow>
								}
								key={device.deviceId}
							>
								<>
									{!!device.tokens?.length &&
										device.tokens
											.slice()
											.sort((a, b) => {
												if (!a || !b || a.issued === b.issued) return 0;
												return Date.parse(a.issued as string) < Date.parse(b.issued as string) ? 1 : -1;
											})
											.map((token) => (
												<SpacedInfoRow compact key={token.id}>
													<InfoColumn width="30%">
														<InfoColumnValue>Issued {format(token.issued)}</InfoColumnValue>
													</InfoColumn>
													<InfoColumn width="35%">
														<InfoColumnValue>Access token expiry: {format(token.accessTokenExpiration)}</InfoColumnValue>
													</InfoColumn>
													<InfoColumn width="35%">
														<InfoColumnValue>Refresh token expiry: {format(token.refreshTokenExpiration)}</InfoColumnValue>
													</InfoColumn>
												</SpacedInfoRow>
											))}
								</>
							</Accordion>
						))}
				</section>
			)}
		</>
	);

	function GenerateOTP() {
		return (
			<span
				title="Generer engangskode for enhetstesting"
				onClick={async () => {
					const { data } = await createUserTestOtp();
					setOtp(data?.otp || "");
				}}
				style={{ cursor: "pointer", float: "right" }}
			>
				<FontAwesomeIcon icon={faArrowRotateRight} />
			</span>
		);
	}

	function AppButtons() {
		return (
			<Buttons>
				<Button
					style={{ width: "100%", borderBottomRightRadius: 0, borderTopRightRadius: 0, borderRight: "none" }}
					onClick={async () => {
						if (showAppToken) {
							return setShowAppToken("");
						}
						const { data } = await createUserTestToken({
							variables: {
								userId: userId || "",
							},
						});
						const tokenObject = JSON.stringify({
							...data?.createUserTestToken,
							refreshToken: "_____",
							refreshTokenExpiration: data?.createUserTestToken?.accessTokenExpiration,
						});
						setShowAppToken(tokenObject);
					}}
				>
					{showAppToken ? "Lukk appvisning" : "Åpne appvisning"}
				</Button>
				<Button
					style={{
						width: "54px",
						padding: "0",
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
						display: "inline-block",
						flex: "none",
					}}
					onClick={async () => {
						if (showAppToken) {
							return setShowAppToken("");
						}
						const { data } = await createUserTestToken({
							variables: {
								userId: userId || "",
							},
						});
						const tokenObject = JSON.stringify({
							...data?.createUserTestToken,
							refreshToken: "_____",
							refreshTokenExpiration: data?.createUserTestToken?.accessTokenExpiration,
						});

						const url = `${process.env.REACT_APP_MITT_NTE_APP_BASE_URL}?token=${tokenObject}`;
						const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=390,height=844,left=100,top=100`;
						window.open(url, `${user?.name} App`, params);
					}}
				>
					<FontAwesomeIcon icon={faUpRightFromSquare} />
				</Button>
			</Buttons>
		);
	}

	function ResidenceAccordion({ residence }: { residence: Residence }) {
		if (!residence) return <></>;

		return (
			<Accordion
				header={
					<>
						<span style={{ marginRight: 20 }}>
							{`${residence.address?.streetAddress}, ${residence.address?.postalCode} ${residence.address?.postalPlace} ${
								residence.alias ? `(${residence.alias})` : ""
							}`}
						</span>
						<ActiveBadge active={residenceHasActiveContract(residence)} />
					</>
				}
			>
				<>{residence.meters?.length && residence.meters?.map((meter: any, i) => <MeterAccordion meter={meter as Meter} key={i} />)}</>
			</Accordion>
		);
	}

	function MeterAccordion({ meter }: { meter: Meter }) {
		const { data } = useGetGridOwnersQuery();

		if (!meter) return <></>;

		return (
			<Accordion
				header={
					<FlexRow>
						<FlexColumn>Måler: {meter.meterId}</FlexColumn>
						<FlexColumn>Målepunkt: <a href={`${getUcMeteringvaluesBaseLink()}/admin/events/maalerpunkt/${meter.meteringPointId}`} target="_blank" rel="noreferrer">{meter.meteringPointId}</a></FlexColumn>
						<FlexColumn>
							Prisområde: {spotpriceAreaDisplayName(meter.priceZone)} ({meter.priceZone})
						</FlexColumn>
						<FlexColumn>
							Omregningsfaktor: {meter.scaling?.factor} (fra {format(meter.scaling?.fromDate, "dd.MM.yyyy")}
							{meter.scaling?.validated !== null ? " validert " + format(meter.scaling?.validated, "dd.MM.yyyy") : " ikke validert"})
						</FlexColumn>
						<FlexColumn>
							Netteier: {meter.gridOwner}{" "}
							{!data?.gridOwners.find((o) => o.fraktalName === meter.gridOwner)?.ecomonId && (
								<a href={`/grid-templates/create/${meter.gridOwner}`}>
									<WarningBadge style={{ padding: 3 }} title="Sammenkoble denne netteieren">
										<FontAwesomeIcon icon={faLink} />
									</WarningBadge>
								</a>
							)}
						</FlexColumn>
						<FlexColumn>
							Klassifisering: {meter.classification?.classification} | {meter.classification?.consumptionCode}
						</FlexColumn>
						<FlexColumn>
							<ActiveBadge active={meterHasActiveContract(meter)} />
						</FlexColumn>
						<FlexColumn>
							Data sist oppdatert: måleverdier: {format(meter.processingEvents?.meteringValuesEvent?.timestamp, "dd.MM.yyyy hh:mm")} (
							{meter.processingEvents?.meteringValuesEvent?.status})
							{meter.processingEvents?.meteringValuesEvent?.status === ProcessingStatus.SUCCESS ? (
								<FontAwesomeIcon width="30px" icon={faCircleCheck} />
							) : (
								<FontAwesomeIcon width="30px" icon={faBan} />
							)}
							kostnader: {format(meter.processingEvents?.cashFlowEvent?.timestamp, "dd.MM.yyyy hh:mm")} (
							{meter.processingEvents?.cashFlowEvent?.status})
							{meter.processingEvents?.cashFlowEvent?.status === ProcessingStatus.SUCCESS ? (
								<FontAwesomeIcon width="30px" icon={faCircleCheck} />
							) : (
								<FontAwesomeIcon width="30px" icon={faBan} />
							)}
						</FlexColumn>
					</FlexRow>
				}
			>
				<>
					{meter.electricityContracts?.length &&
						meter.electricityContracts
							.slice()
							.sort((a, b) => {
								if (!a || !b) return 0;
								return Date.parse(a.dateFrom) < Date.parse(b.dateFrom) ? 1 : -1;
							})
							.map((contract: ElectricityContract, i: number) => <ContractInfo contract={contract} key={i} />)}
				</>
			</Accordion>
		);
	}

	function ContractInfo({ contract }: { contract: ElectricityContract }) {
		if (contract.productName !== "Gjennomfakturert")
			return (
				<SpacedInfoRow
					compact
					borderColor={(contract.status === ContractStatus.ACTIVE && "#7dc189") || (contract.status === ContractStatus.CEASED && "#fe724f")}
					backgroundColor={contract.status === ContractStatus.ACTIVE && "#7dc18920"}
				>
					<InfoColumn>
						<InfoColumnValue>
							<TrafficLightBadge green={contract.status === ContractStatus.ACTIVE} red={contract.status === ContractStatus.CEASED}>
								<span>{contractStatusDisplayName(contract.status)}</span>
							</TrafficLightBadge>
						</InfoColumnValue>
					</InfoColumn>
					<InfoColumn>
						<InfoColumnValue>
							Avtale: {contract.productName} (<a href={`${getUcBaseLink()}/#/products/edit/${contract.productCode}`}>{contract.productCode}</a>)
						</InfoColumnValue>
					</InfoColumn>
					<InfoColumn>
						<InfoColumnValue>
							Avtaleperiode: {`${format(contract.dateFrom, "P")} - ${contract.dateTo !== "2099-12-31" ? format(contract.dateTo, "P") : ""}`}
						</InfoColumnValue>
					</InfoColumn>
				</SpacedInfoRow>
			);

		return null;
	}
}

const SectionTitle = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin: 40px auto 20px;
`;

const SpacedInfoRow = styled(InfoRow)`
	margin: 10px auto;
`;

const Buttons = styled.div`
	width: 100%;
	display: flex;
`;

const Button = styled.button`
	margin: 0;
`;

const ActionButton = styled.button`
	height: 54px;
	width: 54px;
	padding: 0;
	border-radius: 0;
	border-left: none;

	&:first-child {
		border-radius: 4px 0 0 4px;
		border-left: inherit;
	}

	&:last-child {
		border-radius: 0 4px 4px 0;
	}

	&:only-child {
		border-radius: 4px;
	}
`;
