export function isDevEnv() {
	const base = process.env.REACT_APP_MITT_NTE_APP_BASE_URL || '';
	return base.indexOf('.nte.dev') !== -1;
}

export function getUcBaseLink() {
	return `https://widget${isDevEnv() ? ".stag" : ""}.utilitycloud.app`;
}

export function getUcMeteringvaluesBaseLink() {
	return `https://uc-meteringvalues.marked.azure.nte.${isDevEnv() ? "dev" : "no"}`;
}

export function getUcEventconsumerBaseLink() {
	return `https://uc-eventconsumer.marked.azure.nte.${isDevEnv() ? "dev" : "no"}`;
}
