// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HelpPagesListPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HelpPagesListPageQuery = { paginateHelpPages?: { total?: any, items?: Array<{ id?: string, published: boolean, title?: string, order: number, locations?: Array<Types.HelpPageLocation>, segmentIn?: Array<string>, segmentNotIn?: Array<string>, createdAt?: string, image?: { url?: string, reference?: string, provider?: string }, content?: { json?: string, html?: string } }> } };

export type HelpPageDetailsFragment = { id?: string, published: boolean, title?: string, order: number, locations?: Array<Types.HelpPageLocation>, segmentIn?: Array<string>, segmentNotIn?: Array<string>, createdAt?: string, image?: { url?: string, reference?: string, provider?: string }, content?: { json?: string, html?: string } };

export const HelpPageDetailsFragmentDoc = gql`
    fragment HelpPageDetails on HelpPage {
  id
  published
  title
  order
  locations
  image {
    url
    reference
    provider
  }
  content {
    json
    html
  }
  segmentIn
  segmentNotIn
  createdAt
}
    `;
export const HelpPagesListPageDocument = gql`
    query HelpPagesListPage {
  paginateHelpPages {
    total
    items {
      ...HelpPageDetails
    }
  }
}
    ${HelpPageDetailsFragmentDoc}`;

/**
 * __useHelpPagesListPageQuery__
 *
 * To run a query within a React component, call `useHelpPagesListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpPagesListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpPagesListPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useHelpPagesListPageQuery(baseOptions?: Apollo.QueryHookOptions<HelpPagesListPageQuery, HelpPagesListPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HelpPagesListPageQuery, HelpPagesListPageQueryVariables>(HelpPagesListPageDocument, options);
      }
export function useHelpPagesListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HelpPagesListPageQuery, HelpPagesListPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HelpPagesListPageQuery, HelpPagesListPageQueryVariables>(HelpPagesListPageDocument, options);
        }
export type HelpPagesListPageQueryHookResult = ReturnType<typeof useHelpPagesListPageQuery>;
export type HelpPagesListPageLazyQueryHookResult = ReturnType<typeof useHelpPagesListPageLazyQuery>;
export type HelpPagesListPageQueryResult = Apollo.QueryResult<HelpPagesListPageQuery, HelpPagesListPageQueryVariables>;