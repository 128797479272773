// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import { ContentDetailsFragmentDoc } from './ContentPage.graphql-gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertContentMutationVariables = Types.Exact<{
  content: Types.ContentInput;
}>;


export type UpsertContentMutation = { upsertContent?: { id?: string, title?: string, content?: string, published: boolean, publishDate?: string, segmentIn?: Array<string>, segmentNotIn?: Array<string>, uniqueImpressions: any, pageTitle?: string, image?: { url?: string, reference?: string, provider?: string }, link?: { url?: string, type?: Types.ContentLinkType, view?: string, viewType?: string }, pageImage?: { url?: string, reference?: string, provider?: string }, pageContent?: { json?: string, html?: string } } };

export type SegmentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SegmentsQuery = { segments?: Array<{ value?: string, label?: string }> };

export type CountUsersWithSegmentsQueryVariables = Types.Exact<{
  segmentIn: Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>;
  segmentNotIn: Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CountUsersWithSegmentsQuery = { countUsersWithSegments?: { total: any, inSegments: any } };


export const UpsertContentDocument = gql`
    mutation UpsertContent($content: ContentInput!) {
  upsertContent(content: $content) {
    ...ContentDetails
  }
}
    ${ContentDetailsFragmentDoc}`;
export type UpsertContentMutationFn = Apollo.MutationFunction<UpsertContentMutation, UpsertContentMutationVariables>;

/**
 * __useUpsertContentMutation__
 *
 * To run a mutation, you first call `useUpsertContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertContentMutation, { data, loading, error }] = useUpsertContentMutation({
 *   variables: {
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpsertContentMutation(baseOptions?: Apollo.MutationHookOptions<UpsertContentMutation, UpsertContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertContentMutation, UpsertContentMutationVariables>(UpsertContentDocument, options);
      }
export type UpsertContentMutationHookResult = ReturnType<typeof useUpsertContentMutation>;
export type UpsertContentMutationResult = Apollo.MutationResult<UpsertContentMutation>;
export type UpsertContentMutationOptions = Apollo.BaseMutationOptions<UpsertContentMutation, UpsertContentMutationVariables>;
export const SegmentsDocument = gql`
    query Segments {
  segments {
    value
    label
  }
}
    `;

/**
 * __useSegmentsQuery__
 *
 * To run a query within a React component, call `useSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSegmentsQuery(baseOptions?: Apollo.QueryHookOptions<SegmentsQuery, SegmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SegmentsQuery, SegmentsQueryVariables>(SegmentsDocument, options);
      }
export function useSegmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SegmentsQuery, SegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SegmentsQuery, SegmentsQueryVariables>(SegmentsDocument, options);
        }
export type SegmentsQueryHookResult = ReturnType<typeof useSegmentsQuery>;
export type SegmentsLazyQueryHookResult = ReturnType<typeof useSegmentsLazyQuery>;
export type SegmentsQueryResult = Apollo.QueryResult<SegmentsQuery, SegmentsQueryVariables>;
export const CountUsersWithSegmentsDocument = gql`
    query CountUsersWithSegments($segmentIn: [String]!, $segmentNotIn: [String]!) {
  countUsersWithSegments(segmentIn: $segmentIn, segmentNotIn: $segmentNotIn) {
    total
    inSegments
  }
}
    `;

/**
 * __useCountUsersWithSegmentsQuery__
 *
 * To run a query within a React component, call `useCountUsersWithSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountUsersWithSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountUsersWithSegmentsQuery({
 *   variables: {
 *      segmentIn: // value for 'segmentIn'
 *      segmentNotIn: // value for 'segmentNotIn'
 *   },
 * });
 */
export function useCountUsersWithSegmentsQuery(baseOptions: Apollo.QueryHookOptions<CountUsersWithSegmentsQuery, CountUsersWithSegmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountUsersWithSegmentsQuery, CountUsersWithSegmentsQueryVariables>(CountUsersWithSegmentsDocument, options);
      }
export function useCountUsersWithSegmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountUsersWithSegmentsQuery, CountUsersWithSegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountUsersWithSegmentsQuery, CountUsersWithSegmentsQueryVariables>(CountUsersWithSegmentsDocument, options);
        }
export type CountUsersWithSegmentsQueryHookResult = ReturnType<typeof useCountUsersWithSegmentsQuery>;
export type CountUsersWithSegmentsLazyQueryHookResult = ReturnType<typeof useCountUsersWithSegmentsLazyQuery>;
export type CountUsersWithSegmentsQueryResult = Apollo.QueryResult<CountUsersWithSegmentsQuery, CountUsersWithSegmentsQueryVariables>;