// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import { HelpPageDetailsFragmentDoc } from './HelpPagesListPage.graphql-gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertHelpPageMutationVariables = Types.Exact<{
  helpPage: Types.HelpPageInput;
}>;


export type UpsertHelpPageMutation = { upsertHelpPage?: { id?: string, published: boolean, title?: string, order: number, locations?: Array<Types.HelpPageLocation>, segmentIn?: Array<string>, segmentNotIn?: Array<string>, createdAt?: string, image?: { url?: string, reference?: string, provider?: string }, content?: { json?: string, html?: string } } };

export type DeleteHelpPageMutationVariables = Types.Exact<{
  helpPageId: Types.Scalars['String'];
}>;


export type DeleteHelpPageMutation = { deleteHelpPage?: { id?: string, published: boolean, title?: string, order: number, locations?: Array<Types.HelpPageLocation>, segmentIn?: Array<string>, segmentNotIn?: Array<string>, createdAt?: string, image?: { url?: string, reference?: string, provider?: string }, content?: { json?: string, html?: string } } };

export type UpsertHelpPagePageQueryVariables = Types.Exact<{
  helpPageId: Types.Scalars['String'];
}>;


export type UpsertHelpPagePageQuery = { singleHelpPage?: { id?: string, published: boolean, title?: string, order: number, locations?: Array<Types.HelpPageLocation>, segmentIn?: Array<string>, segmentNotIn?: Array<string>, createdAt?: string, image?: { url?: string, reference?: string, provider?: string }, content?: { json?: string, html?: string } } };


export const UpsertHelpPageDocument = gql`
    mutation UpsertHelpPage($helpPage: HelpPageInput!) {
  upsertHelpPage(helpPage: $helpPage) {
    ...HelpPageDetails
  }
}
    ${HelpPageDetailsFragmentDoc}`;
export type UpsertHelpPageMutationFn = Apollo.MutationFunction<UpsertHelpPageMutation, UpsertHelpPageMutationVariables>;

/**
 * __useUpsertHelpPageMutation__
 *
 * To run a mutation, you first call `useUpsertHelpPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertHelpPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertHelpPageMutation, { data, loading, error }] = useUpsertHelpPageMutation({
 *   variables: {
 *      helpPage: // value for 'helpPage'
 *   },
 * });
 */
export function useUpsertHelpPageMutation(baseOptions?: Apollo.MutationHookOptions<UpsertHelpPageMutation, UpsertHelpPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertHelpPageMutation, UpsertHelpPageMutationVariables>(UpsertHelpPageDocument, options);
      }
export type UpsertHelpPageMutationHookResult = ReturnType<typeof useUpsertHelpPageMutation>;
export type UpsertHelpPageMutationResult = Apollo.MutationResult<UpsertHelpPageMutation>;
export type UpsertHelpPageMutationOptions = Apollo.BaseMutationOptions<UpsertHelpPageMutation, UpsertHelpPageMutationVariables>;
export const DeleteHelpPageDocument = gql`
    mutation DeleteHelpPage($helpPageId: String!) {
  deleteHelpPage(helpPageId: $helpPageId) {
    ...HelpPageDetails
  }
}
    ${HelpPageDetailsFragmentDoc}`;
export type DeleteHelpPageMutationFn = Apollo.MutationFunction<DeleteHelpPageMutation, DeleteHelpPageMutationVariables>;

/**
 * __useDeleteHelpPageMutation__
 *
 * To run a mutation, you first call `useDeleteHelpPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHelpPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHelpPageMutation, { data, loading, error }] = useDeleteHelpPageMutation({
 *   variables: {
 *      helpPageId: // value for 'helpPageId'
 *   },
 * });
 */
export function useDeleteHelpPageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHelpPageMutation, DeleteHelpPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHelpPageMutation, DeleteHelpPageMutationVariables>(DeleteHelpPageDocument, options);
      }
export type DeleteHelpPageMutationHookResult = ReturnType<typeof useDeleteHelpPageMutation>;
export type DeleteHelpPageMutationResult = Apollo.MutationResult<DeleteHelpPageMutation>;
export type DeleteHelpPageMutationOptions = Apollo.BaseMutationOptions<DeleteHelpPageMutation, DeleteHelpPageMutationVariables>;
export const UpsertHelpPagePageDocument = gql`
    query UpsertHelpPagePage($helpPageId: String!) {
  singleHelpPage(helpPageId: $helpPageId) {
    ...HelpPageDetails
  }
}
    ${HelpPageDetailsFragmentDoc}`;

/**
 * __useUpsertHelpPagePageQuery__
 *
 * To run a query within a React component, call `useUpsertHelpPagePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpsertHelpPagePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpsertHelpPagePageQuery({
 *   variables: {
 *      helpPageId: // value for 'helpPageId'
 *   },
 * });
 */
export function useUpsertHelpPagePageQuery(baseOptions: Apollo.QueryHookOptions<UpsertHelpPagePageQuery, UpsertHelpPagePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpsertHelpPagePageQuery, UpsertHelpPagePageQueryVariables>(UpsertHelpPagePageDocument, options);
      }
export function useUpsertHelpPagePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpsertHelpPagePageQuery, UpsertHelpPagePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpsertHelpPagePageQuery, UpsertHelpPagePageQueryVariables>(UpsertHelpPagePageDocument, options);
        }
export type UpsertHelpPagePageQueryHookResult = ReturnType<typeof useUpsertHelpPagePageQuery>;
export type UpsertHelpPagePageLazyQueryHookResult = ReturnType<typeof useUpsertHelpPagePageLazyQuery>;
export type UpsertHelpPagePageQueryResult = Apollo.QueryResult<UpsertHelpPagePageQuery, UpsertHelpPagePageQueryVariables>;