import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {Direction, Flex} from "components/atoms/Flex";
import {MainLayoutPageHeader} from "components/organisms/MainLayout";
import {ErrorWrapper} from "components/organisms/form/BaseField";
import Button from "components/organisms/form/Button";
import Form from "components/organisms/form/Form";
import TextField from "components/organisms/form/TextField";
import TextareaField from "components/organisms/form/TextareaField";
import ToggleField from "components/organisms/form/ToggleField";

import {DangerButton} from "../../components/atoms/Button";
import ContentPagePreview from "../../components/molecules/ContentPagePreview";
import FeedPreview from "../../components/molecules/FeedPreview";
import ContentEditorField from "../../components/organisms/form/ContentEditorField";
import SelectField from "../../components/organisms/form/SelectField";
import {ContentSchema, ContentSegmentsEditor} from "./CreateContentPage";
import {useSegmentsQuery, useUpsertContentMutation} from "./CreateContentPage.graphql-gen";
import {SingleInnholdQuery, useDeleteContentMutation, useSingleInnholdQuery} from "./EditContentPage.graphql-gen";

export default function EditContentPage() {
	const { contentId } = useParams<{ contentId: string }>();
	const navigate = useNavigate();
	const [upsertContent, { loading: upsertLoading, error: upsertError }] = useUpsertContentMutation();
	const [deleteContent, { loading: deleteLoading, error: deleteError }] = useDeleteContentMutation();
	const [previewState, setPreviewState] = useState<Partial<SingleInnholdQuery["singleContent"]>>({});
	const {
		data,
		loading: queryLoading,
		error: queryError,
	} = useSingleInnholdQuery({
		variables: {
			contentId: contentId || "",
		},
	});
	const { data: segmentsData } = useSegmentsQuery();

	const [linkType, setLinkType] = useState<string>(data?.singleContent?.link?.type || "WEB");

	useEffect(() => {
		setLinkType(data?.singleContent?.link?.type || "WEB");
		setPreviewState(data?.singleContent);
	}, [data?.singleContent?.id]);

	const error = upsertError || queryError || deleteError;
	const loading = upsertLoading || queryLoading || deleteLoading;

	return (
		<div>
			<MainLayoutPageHeader title="Rediger innhold" />
			<Flex gap="16px">
				<Form
					loading={loading}
					value={{
						...data?.singleContent,
						publishDate: new Date(data?.singleContent?.publishDate ? data.singleContent.publishDate : Date.now())
							.toISOString()
							.substring(0, new Date().toISOString().length - 8),
					}}
					key={data?.singleContent?.id}
					onSubmit={async (data) => {
						const { errors } = await upsertContent({
							variables: {
								content: {
									id: contentId,
									...data,
									publishDate: new Date(data.publishDate).toISOString(),
								},
							},
						});

						!errors && navigate(`/content`);
					}}
					schema={ContentSchema}
				>
					<Flex direction={Direction.column} gap="16px">
						<ToggleField name="published" label="Publisert" />
						<TextField
							name="title"
							label="Tittel"
							onChange={(e) => {
								const target = e.target as any;
								setPreviewState((oldState) => {
									return {
										...oldState,
										title: target.value || "",
									};
								});
							}}
						/>
						<TextareaField
							name="content"
							label="Innhold"
							onChange={(e) => {
								const target = e.target as any;
								setPreviewState((oldState) => {
									return {
										...oldState,
										content: target.value || "",
									};
								});
							}}
						/>
						<TextField type="datetime-local" name="publishDate" label="Publiseringsdato" />

						<div>
							<TextField
								name="image.url"
								label="Bilde"
								onChange={(e) => {
									const target = e.target as any;
									setPreviewState((oldState) => {
										return {
											...oldState,
											image: {
												...oldState?.image,
												url: target.value || "",
											},
										};
									});
								}}
							/>
							<TextField name="image.reference" type="hidden" value="" />
							<TextField name="image.provider" type="hidden" value="" />
						</div>

						<div>
							<SelectField
								name="link.type"
								label="Gå til"
								options={[
									{ value: "NO_LINK", label: "- vis kun innhold -" },
									{ value: "WEB", label: "Lenke" },
									{ value: "APP_CONTENT_PAGE", label: "Innholdsside i app" },
								]}
								onChange={(linkType) => setLinkType(linkType)}
							/>
							{linkType === "NO_LINK" && (
								<div style={{ display: "none" }}>
									<TextField name="link.url" type="hidden" />
									<TextField name="link.view" type="hidden" value="" />
									<TextField name="link.viewType" type="hidden" value="" />
								</div>
							)}
							{linkType === "WEB" && (
								<div>
									<TextField name="link.url" label="Lenke" />
									<TextField name="link.view" type="hidden" value="" />
									<TextField name="link.viewType" type="hidden" value="" />
								</div>
							)}
							{linkType === "APP_CONTENT_PAGE" && (
								<div style={{ display: "none" }}>
									<TextField name="link.url" value="" type="hidden" />
									<TextField name="link.view" type="hidden" value="content_page" />
									<TextField name="link.viewType" type="hidden" value="stack" />
								</div>
							)}
						</div>
						{linkType === "APP_CONTENT_PAGE" && (
							<Flex direction={Direction.column} gap="16px">
								<TextField
									name="pageTitle"
									label="Sidetittel"
									onChange={(e) => {
										const target = e.target as any;
										setPreviewState((oldState) => {
											return {
												...oldState,
												pageTitle: target.value || "",
											};
										});
									}}
								/>
								<div>
									<TextField
										name="pageImage.url"
										onChange={(e) => {
											const target = e.target as any;
											setPreviewState((oldState) => {
												return {
													...oldState,
													pageImage: {
														...oldState?.pageImage,
														url: target.value || "",
													},
												};
											});
										}}
										label="Bilde"
									/>
									<TextField name="pageImage.reference" type="hidden" value="" />
									<TextField name="pageImage.provider" type="hidden" value="" />
								</div>
								<ContentEditorField
									name="pageContent"
									label="Sideinnhold"
									onChange={(value) => {
										setPreviewState((oldState) => {
											return {
												...oldState,
												pageContent: value,
											};
										});
									}}
								/>
							</Flex>
						)}

						<ContentSegmentsEditor key={data?.singleContent?.id} />

						{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
						<Button>Lagre innhold</Button>

						<DangerButton
							type="button"
							onClick={async () => {
								if (!window.confirm(`Sikker på at du ønsker å slette innholdet?`)) {
									return;
								}

								const { errors } = await deleteContent({
									variables: { contentId: contentId || "" },
								});

								if (errors) {
									return console.error(errors);
								}

								navigate("/content");
							}}
						>
							Slett innhold
						</DangerButton>
					</Flex>
				</Form>
				<Flex direction={Direction.column} gap="16px" grow={0} shrink={0} style={{ width: "390px" }}>
					<FeedPreview
						title={previewState?.title || ""}
						content={previewState?.content || ""}
						date={previewState?.publishDate || new Date()}
						imageUrl={previewState?.image?.url || ""}
					/>
					{linkType === "APP_CONTENT_PAGE" && (
						<ContentPagePreview
							title={previewState?.pageTitle || ""}
							contentHtml={previewState?.pageContent?.html || ""}
							imageUrl={previewState?.pageImage?.url || ""}
						/>
					)}
					<div
						style={{
							display: "inline-block",
							width: "390px",
						}}
					/>
				</Flex>
			</Flex>
		</div>
	);
}
