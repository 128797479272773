// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import { ContentDetailsFragmentDoc } from './ContentPage.graphql-gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SingleInnholdQueryVariables = Types.Exact<{
  contentId: Types.Scalars['String'];
}>;


export type SingleInnholdQuery = { singleContent?: { id?: string, title?: string, content?: string, published: boolean, publishDate?: string, segmentIn?: Array<string>, segmentNotIn?: Array<string>, uniqueImpressions: any, pageTitle?: string, image?: { url?: string, reference?: string, provider?: string }, link?: { url?: string, type?: Types.ContentLinkType, view?: string, viewType?: string }, pageImage?: { url?: string, reference?: string, provider?: string }, pageContent?: { json?: string, html?: string } } };

export type DeleteContentMutationVariables = Types.Exact<{
  contentId: Types.Scalars['String'];
}>;


export type DeleteContentMutation = { deleteContent?: { id?: string, title?: string, content?: string, published: boolean, publishDate?: string, segmentIn?: Array<string>, segmentNotIn?: Array<string>, uniqueImpressions: any, pageTitle?: string, image?: { url?: string, reference?: string, provider?: string }, link?: { url?: string, type?: Types.ContentLinkType, view?: string, viewType?: string }, pageImage?: { url?: string, reference?: string, provider?: string }, pageContent?: { json?: string, html?: string } } };


export const SingleInnholdDocument = gql`
    query singleInnhold($contentId: String!) {
  singleContent(contentId: $contentId) {
    ...ContentDetails
  }
}
    ${ContentDetailsFragmentDoc}`;

/**
 * __useSingleInnholdQuery__
 *
 * To run a query within a React component, call `useSingleInnholdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleInnholdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleInnholdQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useSingleInnholdQuery(baseOptions: Apollo.QueryHookOptions<SingleInnholdQuery, SingleInnholdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SingleInnholdQuery, SingleInnholdQueryVariables>(SingleInnholdDocument, options);
      }
export function useSingleInnholdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleInnholdQuery, SingleInnholdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SingleInnholdQuery, SingleInnholdQueryVariables>(SingleInnholdDocument, options);
        }
export type SingleInnholdQueryHookResult = ReturnType<typeof useSingleInnholdQuery>;
export type SingleInnholdLazyQueryHookResult = ReturnType<typeof useSingleInnholdLazyQuery>;
export type SingleInnholdQueryResult = Apollo.QueryResult<SingleInnholdQuery, SingleInnholdQueryVariables>;
export const DeleteContentDocument = gql`
    mutation deleteContent($contentId: String!) {
  deleteContent(contentId: $contentId) {
    ...ContentDetails
  }
}
    ${ContentDetailsFragmentDoc}`;
export type DeleteContentMutationFn = Apollo.MutationFunction<DeleteContentMutation, DeleteContentMutationVariables>;

/**
 * __useDeleteContentMutation__
 *
 * To run a mutation, you first call `useDeleteContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentMutation, { data, loading, error }] = useDeleteContentMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useDeleteContentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContentMutation, DeleteContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContentMutation, DeleteContentMutationVariables>(DeleteContentDocument, options);
      }
export type DeleteContentMutationHookResult = ReturnType<typeof useDeleteContentMutation>;
export type DeleteContentMutationResult = Apollo.MutationResult<DeleteContentMutation>;
export type DeleteContentMutationOptions = Apollo.BaseMutationOptions<DeleteContentMutation, DeleteContentMutationVariables>;