import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { z } from "zod";

import { Direction, Flex } from "components/atoms/Flex";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import Button from "components/organisms/form/Button";
import Form from "components/organisms/form/Form";
import TextField from "components/organisms/form/TextField";
import ToggleField from "components/organisms/form/ToggleField";

import ContentEditorField from "../../components/organisms/form/ContentEditorField";
import { ContentLinkType, HelpPageLocation } from "../../generated/graphql-types";
import { ContentSegmentsEditor } from "pages/Content/CreateContentPage";
import { useUpsertHelpPageMutation, useUpsertHelpPagePageQuery, useDeleteHelpPageMutation } from "./UpsertHelpPagePage.graphql-gen";
import MultiSelectField from "components/organisms/form/MultiSelectField";

export const HelpPageSchema = z.object({
	published: z.boolean(),
	segmentIn: z.array(z.string()),
	segmentNotIn: z.array(z.string()),
	locations: z.array(z.string()),
	image: z
		.object({
			url: z.string().optional(),
			reference: z.string().optional(),
			provider: z.string().optional(),
		})
		.optional(),
	link: z
		.object({
			url: z.string().optional(),
			type: z.nativeEnum(ContentLinkType).optional(),
			view: z.string().optional(),
			viewType: z.string().optional(),
		})
		.optional(),
	title: z.string().optional(),
	content: z
		.object({
			html: z.string().optional(),
			json: z.string().optional(),
		})
		.optional(),
	order: z.coerce.number()
});

export default function UpsertHelpPagesPage() {
	const { helpPageId } = useParams<{ helpPageId?: string }>();
	const navigate = useNavigate();
	const [upsertHelpPage, { loading, error }] = useUpsertHelpPageMutation();
	const [deleteHelpPage, { loading: deleteLoading, error: deleteError }] = useDeleteHelpPageMutation();
	const { data, loading: queryLoading, error: queryError } = useUpsertHelpPagePageQuery({
		variables: {
			helpPageId: helpPageId || ""
		},
		skip: !helpPageId
	});

	return (
		<div>
			<MainLayoutPageHeader title="Ny hjelpeside" />
			<Flex gap="16px">
				<Form
					key={data?.singleHelpPage?.id}
					loading={loading}
					value={data?.singleHelpPage || {
						content: {
							json: "",
							html: "",
						},
					}}
					onSubmit={async (newData) => {
						const id = data?.singleHelpPage?.id || uuid();

						const locations = newData.locations as HelpPageLocation[];

						const { errors } = await upsertHelpPage({
							variables: {
								helpPage: {
									id,
									...newData,
									locations
								},
							},
						});

						if (id === data?.singleHelpPage?.id) {
							return;
						}

						!errors && navigate(`/help-pages/${id}`);
					}}
					schema={HelpPageSchema}
				>
					<Flex direction={Direction.column} gap="16px" style={{paddingTop: '16px'}}>
						<ToggleField name="published" label="Publisert?" />
						<TextField
							name="title"
							label="Tittel"
						/>
						<ContentEditorField
							name="content"
							label="Innhold"
						/>
						<TextField
							name="order"
							label="Rekkefølge"
							type="number"
						/>
						<MultiSelectField
							name="locations"
							label="Lokasjon"
							options={Object.keys(HelpPageLocation).map(l => ({
								label: l,
								value: l,
							}))}
						/>
						<div>
							<TextField
								name="image.url"
								label="Bilde"
							/>
							<TextField name="image.reference" type="hidden" value="" />
							<TextField name="image.provider" type="hidden" value="" />
						</div>

						<ContentSegmentsEditor />

						{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
						<Button loading={loading}>Lag nyt hjelpeside</Button>
						{data?.singleHelpPage?.id &&
							<Button
								loading={deleteLoading}
								type="button"
								onClick={async () => {
									if(!window.confirm("Sikker på at du ønsker å slette " + data.singleHelpPage?.title)){
										return;
									}
									await deleteHelpPage({
										variables: {
											helpPageId: data?.singleHelpPage?.id || ""
										}
									});

									navigate(`/help-pages`);
								}}
								style={{
									background: '#8a0000',
									border: '1px solid #8a0000',
								}}>
								Slett hjelpeside
							</Button>
						}
					</Flex>
				</Form>
			</Flex>
		</div>
	);
}






